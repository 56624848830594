import { Link } from "gatsby-plugin-react-i18next"
import { HeroRight } from "../Hero/HeroImageHandlers"
import { Ingress } from "../Styling/Body"
import HeroBannerProps from "../Hero/HeroTypes"
import { HeroTitle } from "../Styling/Titles"
import { PrimaryBadgeLinkMediumThin } from "../Graphics/Badge"

const SupportSection = ({ data, imgPosition }) => {
  if (data.heroImage?.length > 0) {
    data.heroImage = data.heroImage[0]
    data.heroImage.imagePublicId = data.heroImage?.public_id
  }

  const heroProps: HeroBannerProps = {
    videoUrl: data.videoUrl,
    image: data.heroImage,
  }

  let imgStyle = ""

  if (imgPosition === "right") {
    imgStyle = "relative lg:row-start-1 lg:col-start-1"
  } else {
    imgStyle = "relative lg:row-start-1 lg:col-start-2"
  }

  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 ">
        <div className=" lg:grid lg:grid-cols-2 lg:gap-8">
          <div className={imgStyle}>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <Link to={`/nb/minside/veiledning/${data.slug}/`}>
                    <HeroRight {...heroProps} />
                  </Link>
                </div>
              </figure>
            </div>
          </div>
          <div className=" lg:mt-0">
            <div className="text-base max-w-prose mx-auto text-center lg:max-w-none hover:underline">
              <Link to={`/nb/minside/veiledning/${data.slug}/`}>
                <HeroTitle>
                  <span className="text-header40">{data.title}</span>
                </HeroTitle>
              </Link>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              {data.ingress !== undefined && data.ingress !== null ? <Ingress>{data.ingress.ingress}</Ingress> : <></>}
            </div>
            <div className="lg:hidden flex justify-center px-4">
              <PrimaryBadgeLinkMediumThin title="Les mer" url={`/nb/minside/veiledning/${data.slug}/`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportSection
